import React, { useState, useEffect } from 'react';
import axios from 'axios';

function App() {
  const trial = () => {
    axios.get('/api/trial/get')
    .then((res) => {
      console.log("Success: ");
      console.log(res);
    })
    .catch((err) => {
      console.log("Error: " + err);
    });
  }
  return (
    <div onClick={trial} className="App">
      hello
    </div>
  );
}

export default App;
